export const AUTH_PATH_CHUNK = 'auth';
export const AUTH_PAGE_PATH = `/${AUTH_PATH_CHUNK}`;
export const RESET_PASSWORD_PATH_CHUNK = 'reset';
export const RESET_PASSWORD_EMAIL_PATH_CHUNK = 'reset-password-email';
export const ADMIN_PATH_CHUNK = 'admin';
export const CABINET_PATH_CHUNK = 'cabinet';
export const PROFILE_PATH_CHUNK = 'profile';
export const USER_PATH_CHUNK = 'user';
export const ORGANIZATION_PATH_CHUNK = 'organization';
export const SUBSCRIPTION_PATH_CHUNK = 'subscription';
export const PRODUCTION_CALENDAR_PATH_CHUNK = 'production-calendar';
export const PROJECT_PATH_CHUNK = 'project';
export const ACCESS_PATH_CHUNK = 'access';
export const STAFFING_SUBDIVISION_PATH_CHUNK = 'staffing-subdivision';
export const STAFFING_POSITION_PATH_CHUNK = 'staffing-position';
export const EMPLOYEE_PATH_CHUNK = 'employee';
export const EVENT_CALENDAR_PATH_CHUNK = 'calendar';
export const STUDYING_PROGRAM_PATH_CHUNK = 'studying-program';
export const CHECK_KNOWLEDGE_COMMISSION_PATH_CHUNK = 'check-knowledge-commission';
export const LABOR_PROTECTION_INSTRUCTIONS_PATH_CHUNK = 'labor-protection-instructions';
export const CHECK_KNOWLEDGE_RULE_PATH_CHUNK = 'check-knowledge-rule';
export const LOCAL_REGULATION_PATH_CHUNK = 'local-regulation';
export const MEDICAL_EXAMINATION_UNSCHEDULED_PATH_CHUNK = 'medical-examination-unscheduled';
export const LOCAL_REGULATION_RULE_PATH_CHUNK = 'local-regulation-rule';
export const BRIEFING_RULE_AT_WORK_PATH_CHUNK = 'briefing-rule-at-work';
export const BRIEFING_RULE_FIRE_FIGHTING_LIST_PATH_CHUNK = 'briefing-rule-fire-fighting';
export const CHECK_KNOWLEDGE_UNSCHEDULED_RULE_PATH_CHUNK = 'check-knowledge-unscheduled-rule';
export const BRIEFING_UNSCHEDULED_RULE_AT_WORK_PATH_CHUNK = 'briefing-unscheduled-rule-at-work';
export const BRIEFING_UNSCHEDULED_RULE_FIRE_FIGHTING_PATH_CHUNK = 'briefing-unscheduled-rule-fire-fighting';
export const BRIEFING_RULE_INTRODUCTORY_PATH_CHUNK = 'briefing-rule-introductory';
export const INTERNSHIP_RULE_PATH_CHUNK = 'internship-rule';
export const MEDICAL_EXAMINATION_ORGANIZATION_PATH_CHUNK = 'medical-examination-organization';
export const HAZARD_FACTOR_PATH_CHUNK = 'hazard-factor';
export const MEDICAL_EXAMINATION_RULE_PATH_CHUNK = 'medical-examination-rule';
export const DIRECTORY_ACCIDENT_PATH_CHUNK = 'directory-accident';
export const PSYCHIATRIC_EXAMINATION_RULE_PATH_CHUNK = 'psychiatric_examination_rule';
export const PSYCHIATRIC_EXAMINATION_UNSCHEDULED_RULE_PATH_CHUNK = 'psychiatric_examination_unscheduled_rule';
export const DIRECTORY_ACTIVITY_PATH_CHUNK = 'directory-activity';
export const DIRECTORY_MICROTRAUMA_PATH_CHUNK = 'directory-microtrauma';
export const MICROTRAUMA_MEASURE_PATH_CHUNK = 'microtrauma-measure';
export const MICROTRAUMA_CAUSE_PATH_CHUNK = 'microtrauma-cause';
export const MICROTRAUMA_PATH_CHUNK = 'microtrauma';
export const PROJECT_REPORT_PATH_CHUNK = 'project-report';
export const IPM_PATH_CHUNK = 'ipm';
export const RISK_ASSESSMENT_PATH_CHUNK = 'risk-assessment';
export const RISK_ASSESSMENT_LEVELS_PATH_CHUNK = 'levels';
export const RISK_ASSESSMENT_MEASURES_PATH_CHUNK = 'measures';
export const EMPLOYEE_CHANGE_PATH_CHUNK = 'employee-change';
export const WORKPLACE_PATH_CHUNK = 'workplace';
export const WORKPLACE_GROUP_PATH_CHUNK = 'workplace-group';
export const SAWC_ORGANIZATION_PATH_CHUNK = 'sawc-organization';
export const SAWC_COMMISSION_PATH_CHUNK = 'sawc-commission';
export const SAWC_IMPORT_PATH_CHUNK = 'sawc-import';
export const SAWC_REPORT_PATH_CHUNK = 'sawc-report';
export const ORA_REPORT_PATH_CHUNK = 'ora-report';
export const TAKEN_MEASURE_PATH_CHUNK = 'ora-taken-measure';
export const REQUIRED_MEASURE_PATH_CHUNK = 'ora-required-measure';
export const ORA_EQUIPMENT_PATH_CHUNK = 'ora-equipment';
export const ORA_RAW_MATERIAL_PATH_CHUNK = 'ora-raw-material';
export const ORA_POLLUTER_PATH_CHUNK = 'ora-polluter';
export const ORA_DANGER_GROUP_PATH_CHUNK = 'ora-danger-group';
export const ORA_DANGER_PATH_CHUNK = 'ora-danger';
export const ORA_SOURCE_DANGER_PATH_CHUNK = 'ora-source-danger';
export const ORA_DANGER_EVENT_PATH_CHUNK = 'ora-danger-event';
export const WORKPLACE_ENTITIES_PATH_CHUNK = 'workplace-entities';
export const ORA_COMMISSION_PATH_CHUNK = 'ora-commission';
