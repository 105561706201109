import ReactSelect, { type Props, type ClassNamesConfig as ReactSelectClassNamesConfig } from 'react-select';
import clsx from 'clsx';

import styles from './Select.module.scss';
import { MultiValueRemove } from '@/components/Form/Select/MultiValueRemove';
import { CustomMenuList } from './CustomMenuList';

export type ClassNamesConfig = ReactSelectClassNamesConfig;

export interface SelectValueItem {
  value: any;
  label: any;
}

export type SelectProps = Props & {
  onChange: (data: any) => void;
  isInvalid?: boolean;
  noBorder?: boolean;
  size?: string;
  variant?: string;
};

export const Select = (props: SelectProps) => {
  const {
    isInvalid,
    classNames = {},
    components = {},
    noBorder,
    size,
    variant,
    loadingMessage,
    placeholder = '',
    ...restProps
  } = props;
  return (
    <ReactSelect
      menuPlacement="auto"
      aria-label="react-select"
      {...restProps}
      menuShouldScrollIntoView={false}
      backspaceRemovesValue={false}
      classNames={{
        ...classNames,
        control: (value) =>
          clsx(classNames?.control?.(value) ?? '', styles.control, {
            [styles.noBorder]: noBorder,
            [styles.controlSmall]: size === 'sm',
            [styles.controlPrimary]: variant === 'primary',
            [styles.invalid]: isInvalid,
          }),
        indicatorsContainer: (value) =>
          clsx(`${classNames?.indicatorsContainer?.(value) ?? ''}`, styles.indicatorsContainer, {
            [styles.indicatorContainerSmall]: size === 'sm',
          }),
        valueContainer: (value) =>
          clsx({
            [styles.valueContainerSmall]: size === 'sm',
          }),
        singleValue: (value) =>
          clsx(styles.singleValue, classNames.singleValue?.(value), {
            [styles.singleValueSmall]: size === 'sm',
          }),
        multiValue: (value) => `${classNames?.multiValue?.(value) ?? ''} ${styles.multiValue}`,
        placeholder: (value) => `${classNames?.placeholder?.(value) ?? ''} ${styles.placeholder}`,
        option: (value) => {
          return `${classNames?.option?.(value) ?? ''} ${value.isSelected ? styles.optionSelected : styles.option}`;
        },
      }}
      components={{
        MultiValueRemove,
        ClearIndicator: (props: any) => {
          return (
            <span {...props.innerProps} aria-label="select-clear">
              <i className="bx bx-x"></i>
            </span>
          );
        },
        IndicatorSeparator: () => null,
        MenuList: CustomMenuList,
        ...components,
      }}
      loadingMessage={loadingMessage ?? (() => <></>)}
      // menuIsOpen={true}
      blurInputOnSelect={false}
      placeholder={placeholder}
    />
  );
};
