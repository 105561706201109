/* eslint-disable */

import clsx from 'clsx';
import stylesCommon from '../../Landing.module.scss';
import styles from './LandingHero.module.scss';
import { LandingButton } from '../LandingButton';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { LandingArrowDownIcon } from '../icons/LandingArrowDownIcon';
import { useRef } from 'react';
import { LANDING_SECTION_CLASS } from '@/landing/util';

interface Props {
  openBidModal: () => void;
  goToSection: (id: string, event: React.MouseEvent) => void;
}

export const LandingHero = (props: Props) => {
  const { openBidModal, goToSection } = props;
  const video = useRef<HTMLVideoElement>(null);

  const scrollDown = () => {
    const element = document.getElementById('landing-offer');
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={clsx(styles.heroWrapper, 'landing__hero-scroll', LANDING_SECTION_CLASS)} id="landing-hero">
      <div className={clsx(stylesCommon.wrapper, styles.heroWrapperInner)}>
        <div className={clsx(styles.heroBlock)}>
          <div className={clsx(styles.heroLeft)}>
            <h1 className={clsx(styles.header)}>Цифровые Решения</h1>
            <a className={styles.heroScrollAnchor} onClick={scrollDown}>
              <div>
                Продолжить
                <br />
                просмотр
              </div>
              <LandingArrowDownIcon />
            </a>
          </div>
          <div className={clsx(styles.heroRight)}>
            <div className={clsx(styles.heroText)}>
              Программный комплекс представляет собой цифровую платформу, которая помогает автоматизировать решение
              задач по охране труда.
            </div>
            <div className={styles.heroBottom}>
              <LandingButton variant="fancy" sz="lg" onClick={openBidModal}>
                Попробовать бесплатно
                <LandingChevronRightIcon />
              </LandingButton>
              <a
                href="#landing-help"
                className={styles.heroLink}
                onClick={(event) => {
                  goToSection('landing-service', event);
                }}
              >
                Зачем нужно?
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.heroBgLayer, styles.heroBg)}>
        <video
          src={'/landing-hero-bg.mp4'}
          className={clsx(styles.heroBgLayer)}
          // loop
          // muted
          // autoPlay
          // playsInline
          ref={video}
        />
        <div className={clsx(styles.heroBgLayer, styles.heroBgBlur)} />
        <div className={clsx(styles.heroBgLayer, styles.heroBgImg)} />
      </div>
    </div>
  );
};
