import type { ListResponseType } from '@/models';
import type { ApolloQueryResult, LazyQueryResultTuple, OperationVariables } from '@apollo/client';
import { uniqBy } from 'lodash';

export type QueryResponseType = Record<string, ListResponseType>;

export const getQueryData = (response: any) => {
  const data = response?.data;
  if (!data) return null;
  const fieldName = Object.getOwnPropertyNames(data)[0];
  return response?.data?.[fieldName];
};

export const getQueryCount = (response: ApolloQueryResult<any>) => {
  return getQueryData(response)?.count;
};

export const uniqById = (list: any[]) => uniqBy(list, ({ id }: any) => id);

export const getFieldName = (data: any) => Object.getOwnPropertyNames(data as QueryResponseType)[0];

// TODO remove default array value (to avoid rereners)
// TODO types
export const getItems = (data: any): any[] => data?.[getFieldName(data as QueryResponseType)]?.items ?? [];

export const getData = (data: any) => {
  return data?.[getFieldName(data)];
};

export const getCount = (data: any) => {
  return data?.[getFieldName(data as QueryResponseType)]?.count ?? 0;
};

const createUpdateQuery = (callback: any) => {
  return (previousResult: any, { fetchMoreResult }: any) => {
    const fieldName = getFieldName(fetchMoreResult);
    const newItems = fetchMoreResult[fieldName].items;
    const newResult = {
      [fieldName]: {
        ...fetchMoreResult[fieldName],
        items: uniqById([...previousResult[fieldName].items, ...newItems]),
        count: fetchMoreResult[fieldName].count,
      },
    };
    callback(newResult);
    return newResult;
  };
};

/**
 * Load all items available in chunks size of 'limit'
 * On each iteration the apollo cache gets updated, so the data is always available in lazyQuery data
 */
export async function loadQueryInParts(
  lazyQuery: LazyQueryResultTuple<any, any>,
  limit: number,
  variables: OperationVariables = {},
) {
  const [loadData, { fetchMore }] = lazyQuery;
  let offset = 0;
  let firstResult;
  try {
    firstResult = await loadData({ variables: { ...variables, limit } });
  } catch (e: any) {
    console.log(`loadQueryInParts fail`, e.message);
    return;
  }
  const count = getQueryCount(firstResult);
  let lastData = firstResult.data;

  while (offset + limit < count) {
    offset += limit;
    await fetchMore({
      variables: { ...variables, offset, limit },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const fieldName = getFieldName(previousResult);
        const newItems = fetchMoreResult[fieldName].items;
        const newResult = {
          [fieldName]: {
            ...fetchMoreResult[fieldName],
            items: uniqById([...previousResult[fieldName].items, ...newItems]),
            count: fetchMoreResult[fieldName].count,
          },
        };
        lastData = newResult;
        return newResult;
      },
    });
  }
  return lastData;
}

/**
 * Load all items with specified ids
 */
export async function loadAllIds(
  lazyQuery: LazyQueryResultTuple<any, any>,
  limit: number,
  ids: number[],
  variables: OperationVariables = {},
) {
  const [loadData, { fetchMore }] = lazyQuery;

  let firstResult;
  try {
    firstResult = await loadData({ variables: { ...variables, limit, ids: ids.slice(0, limit) } });
  } catch (e: any) {
    console.log(`loadQueryInParts fail`, e.message);
    return;
  }
  const totalPages = Math.ceil(ids.length / limit);
  let lastData = firstResult.data;
  for (let page = 1; page < totalPages; page++) {
    await fetchMore({
      variables: {
        ids: ids.slice(page * limit, (page + 1) * limit),
      },
      updateQuery: createUpdateQuery((data: any) => {
        lastData = data;
      }),
    });
  }
  return lastData;
}
